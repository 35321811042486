import { TransactionInformationApi } from '@blank/api'
import dayjs from 'dayjs'
import { extractAmount } from 'modules/common/lib/helpers/amount'

export const formatTransactionAmount = (value: number, currency?: string) => {
  const { symbol, decimal, integer } = extractAmount(value, currency)

  const isNegative = value < 0

  return `${isNegative ? '-' : '+'} ${
    isNegative ? integer.replace('-', '') : integer
  },${decimal} ${symbol}`
}

export const formatInlineVATRate = (
  vatArray?: TransactionInformationApi['vat']
) => {
  return vatArray?.map(({ rate }) => (rate ? `${rate}%` : '')).join(', ')
}

export const formatExportTransactionsDate = (rawDate: string) =>
  dayjs.utc(rawDate).toISOString()

/**
 * 1. If the end of month is in the future, return the end of yesterday
 * 2. If the end of month is in the past, return the end of month
 * @param endDate The end date to check
 * @returns The maximum end date
 */
export const getMaxEndDate = (endDate: string) =>
  dayjs
    .unix(
      Math.min(
        dayjs(endDate).utc(true).endOf('month').unix(),
        dayjs().utc(true).subtract(1, 'day').endOf('day').unix()
      )
    )
    .utc()
    .format()
